<template>
  <div
    :id="field.id"
    class="form-editor"
  />
</template>

<script lang="ts" setup>
import './editor.css';
import {
  defineProps,
  PropType,
  ref,
  watchEffect,
  defineEmits, onMounted,
} from 'vue';
import { useTranslate, type EditorField } from 'magner';
import type { OutputData } from '@editorjs/editorjs';
import { setupEditor } from './setup';

const props = defineProps({
  field: {
    type: Object as PropType<EditorField<any>['props']>,
    required: true,
  },
  modelValue: {
    type: [String],
    default: '',
  },
});

const emit = defineEmits(['update:modelValue']);
const { customT } = useTranslate();

const val = ref<string>(props.modelValue);
watchEffect(() => {
  val.value = props.modelValue;
});

const changeVal = (newVal: string) => {
  val.value = newVal;
  emit('update:modelValue', newVal);
};

let parsedData: OutputData;

console.log(val.value)
try {
  parsedData = JSON.parse(val.value);
} catch (_) {
  parsedData = { blocks: [] };
}

onMounted(() => {
  setTimeout(() => {
    setupEditor({
      holder: props.field.id,
      placeholder: customT(props.field.placeholder || ''),
      data: parsedData,
      readOnly: false,
      onChange: (editor) => {
        editor.saver?.save?.().then((outputData) => {
          changeVal(JSON.stringify(outputData));
        });
      },
    });
  }, 500);
});
</script>
