<template>
  <el-tabs
    v-model="activeTab"
    type="card"
    class="setting-prices-table-tabs"
    @tab-change=""
  >
    <el-tab-pane
      v-for="item in items"
      :key="item.tabId"
      :label="item.title"
      :name="item.tabId"
    >
      <el-card style="width: 100%">
        <template #header>
          <div class="card-header">
            <div style="display: flex; justify-content: space-between">
              <h2>{{ item.title }}</h2>

              <div style="display: flex; gap: 20px">
                <el-button 
                  type="danger" 
                  :disabled="!prevItems.length"
                  @click="cancelIncreasePrices(item.tabId)"
                >
                  Отменить наценку
                </el-button>
                <div>
                  <el-input-number
                    v-model="pricePercent"
                    :precision="0"
                    :min="0"
                    :max="99"
                    class="setting-prices-input"
                  />
                </div>
                <el-button type="success" @click="increasePrices(item.tabId)">+</el-button>
              </div>
            </div>
          </div>
        </template>

        <el-table :data="item.table">
          <template v-for="col in item.tableColumns">
            <template v-if="col.prop === 'area'">
              <el-table-column
                fixed="left"
                :prop="col.prop"
                :label="col.label"
                width="100"
                class-name="setting-prices-table-area"
              />
            </template>
            <template v-else>
              <el-table-column
                :fixed="col.prop === 'area' ? 'left' : undefined"
                :prop="col.prop"
                :label="col.label"
                width="95"
              >
                <template #default="scope">
                  <el-input-number
                    v-model="item.table[scope.$index][scope.column.property]"
                    :precision="1"
                    :min="0"
                    :disabled="item.table[scope.$index].disabled && col.prop === 'minPrice'"
                    @change="updateModelValue"
                    style="width: 100%"
                    class="setting-prices-table-input"
                  />
                </template>
              </el-table-column>
            </template>
          </template>
        </el-table>

        <el-form-item 
          v-if="item.minimalPrice !== null" 
          label="Минимальная цена (руб.)" 
          style="margin: 20px 0;"
        >
          <el-input-number 
            :model-value="item.minimalPrice" 
            :min="1" 
            :value-on-clear="1" 
            class="setting-prices-input"
            @blur="+$event.target.value < 1 ? item.minimalPrice = 1 : item.minimalPrice = +$event.target.value" 
          />
        </el-form-item>

        <div v-if="item.options.length" style="margin-top: 40px">
          <template v-for="option in item.options">
            <el-form-item :label="`${option.title} (${option.type === 'absolute' ? 'руб.' : '%'})`" style="margin-bottom: 20px;">
              <el-input-number v-model="option.value" :disabled="option.customLogic" class="setting-prices-input" />
              <p v-if="option.comment" style="padding-left: 10px;color: #888888">{{ option.comment }}</p>
            </el-form-item>
          </template>
        </div>
      </el-card>
    </el-tab-pane>
  </el-tabs>

  <!-- Для вывода первого таба -->
  <span v-show="false">{{ activeTab }}</span>

  <div>
    <h4>Увеличение/уменьшение всех цен</h4>
    <hr>

    <div style="display: flex; gap: 20px">
      <el-button :disabled="!prevItems.length" type="danger" @click="cancelIncreaseAllPrices">Отменить наценку</el-button>
      <div>
        <el-input-number
          v-model="priceAllPercent"
          :precision="0"
          :min="0"
          :max="99"
          class="setting-prices-input"
        />
      </div>
      <el-button type="success" @click="increaseAllPrices">+</el-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  defineProps, defineEmits, PropType, ref, onMounted, watch
} from 'vue';
import type { PricesEntity } from './type';

const props = defineProps({
  form: {
    type: Object as PropType<{items: PricesEntity[]}>,
    required: true,
  },
});

const emits = defineEmits(['update:modelValue']);

const items = ref([...props.form.items]);
const prevItems = ref<typeof props.form.items>([]);
const activeTab = ref('');

const updateModelValue = () => {
  emits('update:modelValue', items.value);
};

onMounted(() => {
  emits('update:modelValue', items.value);
});

const changePrices = (itemId: string, coefficient: number) => {
  items.value?.map((item: PricesEntity) => {
    if (item.tabId === itemId) {
      item.table.map((row) => {
        Object.keys(row).map((key) => {
          if (key !== 'area') {
            row[key] = +row[key] * coefficient;
          }

          return key;
        });

        return row;
      });
    }

    return item;
  });
};

const priceAllPercent = ref(10);
const pricePercent = ref(10);
/**
 * Метод увеличивает цены вкладки на 10%
 * @param itemId
 */
const increasePrices = (itemId: string) => {
  if (pricePercent.value) {
    prevItems.value = JSON.parse(JSON.stringify(items.value));

    console.log(prevItems.value[0])

    changePrices(itemId, (100 + pricePercent.value) / 100);
  }

  updateModelValue();
};

/**
 * Метод уменьшает цены вкладки на 10%
 * @param itemId
 */
const decreasePrices = (itemId: string) => {
  if (pricePercent.value) {
    changePrices(itemId, (100 - pricePercent.value) / 100);
  }

  updateModelValue();
};
/**
 * Метод отменяет увеличение цены
 * @param itemId
 */
 const cancelIncreasePrices = (itemId: string) => {
  items.value?.forEach((item: PricesEntity) => {
    if (item.tabId === itemId) {
      item.table = (prevItems.value.find((prevItem) => prevItem.tabId === itemId) as PricesEntity)?.table || [];
    }
  });

  prevItems.value = [];

  updateModelValue();
};
/**
 * Метод увеличивает все цены на 10%
 */
const increaseAllPrices = () => {
  if (priceAllPercent.value) {
    prevItems.value = JSON.parse(JSON.stringify(items.value));

    items.value.map((item: PricesEntity) => {
      changePrices(item.tabId, (100 + pricePercent.value) / 100);

      return item;
    });
  }

  updateModelValue();
};

/**
 * Метод уменьшает все цены на 10%
 */
const decreaseAllPrices = () => {
  if (priceAllPercent.value) {
    items.value.map((item: PricesEntity) => {
      changePrices(item.tabId, (100 - pricePercent.value) / 100);

      return item;
    });
  }

  updateModelValue();
};
/**
 * Метод отменяет увеличение всех цены
 */
 const cancelIncreaseAllPrices = () => {
  items.value?.forEach((item: PricesEntity) => {
    item.table = (prevItems.value.find((prevItem) => prevItem.tabId === item.tabId) as PricesEntity)?.table || [];
  });

  prevItems.value = [];

  updateModelValue();
};

watch(() => props.form.items, (value) => {
  // eslint-disable-next-line no-return-assign
  setTimeout(() => activeTab.value = value[0].tabId, 0);
})
</script>

<style>
.page-prices .el-input-number__decrease, 
.page-prices .el-input-number__increase {
  opacity: 0 !important;
  pointer-events: none !important;
}

.setting-prices-table-tabs {
  width: 100%;
}

.setting-prices-table-tabs .el-tabs__header {
  height: auto !important;
  border: none !important;
}

.setting-prices-table-tabs  .el-tabs__nav {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  border: none !important;
}

.setting-prices-table-tabs .el-tabs__item {
  border: 1px solid var(--el-border-color-light) !important;
  padding: 0 12px !important;
  height: 36px;
  line-height: 36px;
}

.setting-prices-table-input .el-input__wrapper {
  padding: 0 8px !important;
}

.page-prices .el-table .cell {
  padding: 0 6px !important;
}

.page-prices .el-table th div {
  font-size: 12px;
  line-height: 16px;
}

.setting-prices-table-area .cell {
  word-break: normal !important;
  font-size: 12px;
  line-height: 16px !important;
}

.page-prices .setting-prices-input {
  width: 90px;
}

.page-prices .setting-prices-input .el-input__wrapper {
  padding: 0 8px !important;
}
</style>
